<template>
    <div class="row">
        <div class="col-12">
            <div class="card course-card mw-100 mh-auto p-0 shadow-xss border-0 rounded-lg overflow-hidden">
                <div class="card-body">
                    <div class="row mb-4">
                        <div class="col-12">
                            <div class="card border-0 mb-0 rounded-lg overflow-hidden">
                                <div class="card-body p-lg-5 p-4 bg-black-08">
                                    <span class="font-xsssss fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2 alert-warning d-inline-block text-warning mr-1">Test Programs</span>
                                    <h2 class="fw-700 font-lg d-block lh-4 mb-1 text-white mt-2">Welcome to Test Programs</h2>
                                    <p class="font-xsss fw-500 text-grey-100 lh-30 pr-lg-5 mt-3 mr-lg-5">Elingway is a Foreign Language Learning Platform that Collaborates as TOEFL ITP - TOEFL iBT - TOEIC - IELTS Service Provider.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col-12">
                            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden mb-4">
                                <ul class="nav nav-tabs d-flex align-items-center justify-content-around product-info-tab border-bottom-0 pl-2 pr-2">
                                    <li :class="{'active': $route.name == 'TOEFL'}" class="list-inline-item">
                                        <router-link :class="{'active': $route.name == 'TOEFL'}" :to="{name: 'TOEFL'}" class="fw-700 pb-sm-5 pt-sm-5 xs-mb-2 ml-sm-5 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" title="WIN Analysis">Registration</router-link>
                                    </li>
                                    <li :class="{'active': $route.name == 'TOEFLHistory'}" class="list-inline-item">
                                        <router-link :class="{'active': $route.name == 'TOEFLHistory'}" :to="{name: 'TOEFLHistory'}" class="fw-700 pb-sm-5 pt-sm-5 xs-mb-2 mr-sm-5 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" title="Pretesting, Embedded testing, Post testing">History</router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <div class="col-12">
                            <div class="tab-content" id="pills-tabContent">
                                <div class="tab-pane fade show active" v-if="$route.name == 'TOEFL'">
                                    <Registration></Registration>
                                </div>
                                <div class="tab-pane fade show active" v-if="$route.name == 'TOEFLHistory'">
                                    <History></History>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</template>

<script>
import Registration from './Sub/Registration.vue'
import History from './Sub/History.vue'
export default {
    components: {
        Registration,
        History,
    },
    name: 'TOEFL',
}
</script>
<style scoped>
.underline{
    /* border-bottom: 5px solid #333; */
    width: 100%;
    height: 4px;
    background-color: #333;
    text-align: center;
    display: block;
}
.text-white {
    color: #fff !important;
}
.text-grey-50{
    color: #adb5bd !important;
}
</style>