<template>
    <div class="card course-card mw-100 mh-auto p-0 shadow-xss border-0 rounded-lg overflow-hidden">
        <div class="card-body p-lg-4 bg-white">
            <div class="row justify-content-center">
            <div class="col-12">
                <input type="text" class="form-control" placeholder="Search..." v-model="tableFilter">
            </div>
                <div v-if="!isLoad" class="col-12 py-3">
                    <div class="table-responsive mw-100 pr-2 ml-0">
                        <datatable :class="'table table-hover table-bordered'" :columns="columns" :filter="tableFilter" :data="history" :page="1" :perPage="10">
                            <template name="default" slot-scope="{ row, index }">
                                <tr v-if="row">
                                    <td class="text-center">{{ index+1 }}</td>
                                    <td>{{ row.fullname }}</td>
                                    <td>{{ row.id_no }}</td>
                                    <td>{{ row.partner_id.title }}</td>
                                    <td>{{ row.category_test }}</td>
                                    <td class="text-center">{{ row.test_date | formatDate }}</td>
                                    <td>{{ row.country }}</td>
                                    <td>{{ row.native_language }}</td>
                                    <td class="text-center">{{ row.birth_date | formatDate }}</td>
                                    <td class="text-center">{{ row.sex }}</td>
                                    <td class="text-center">{{ row.times_taken }}</td>
                                    <td> <span class="badge badge-warning p-1 text-white">{{ formatStatus(row.status) }}</span></td>
                                </tr>
                                <tr v-else>
                                    <td colspan="8">Nothing to see here</td>
                                </tr>
                            </template>
                        </datatable>
                    </div>
                    <datatable-pager v-model="page" type="abbreviated"></datatable-pager>
                </div>
                <div v-else class="col-12 text-center py-3">
                    <div class="my-3">
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data() {
        return {
            history: [],
            isLoad: true,
            page: 1,
            tableFilter: '',
            columns: [
                {label: 'No', field: '', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Full Name', field: 'fullname', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center'},
                {label: 'ID Number', field: 'id_no', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center'},
                {label: 'Information Source', field: 'partner_id', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center'},
                {label: 'Test Program', field: 'category_test', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center'},
                {label: 'Test Date', field: 'test_date', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center'},
                {label: 'Country', field: 'country', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center'},                          
                {label: 'Native Language', field: 'native_language', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center'},                          
                {label: 'Birth Date', field: 'birth_date', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center'},                          
                {label: 'Gender', field: 'sex', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center'},                          
                {label: 'Times Taken', field: 'times_taken', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center'},                          
                {label: 'Status', field: '', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},                          
            ]
        }
    },
    created() {
        this.getHistory()
    },
    methods: {
        formatStatus(str = '') {
            if(str == 1) {
                return 'Waiting Approval'
            } else if(str == 2) {
                return 'Waiting For Payment'
            } else if(str == 3) {
                return 'Active (Payed)'
            } else if(str == 4) {
                return 'Expired'
            } else if(str == 5) {
                return 'Refund'
            } else if(str == 6) {
                return 'Canceled'
            }
        },
        async getHistory() {
            await axios.get(`${process.env.VUE_APP_URL_API}/core/toefl`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.history = res.data.data
                this.isLoad = false
            }).catch(err => {
                if (err?.response?.status == 422){
                    this.$swal({
                        toast: true,
                        title: 'TOEFL!',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'TOEFL!',
                        text: 'Failed get history!',
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            }).finally(() => this.process = false)
            console.log(history);
        }
    }
}
</script>