<template>
    <div class="card course-card mw-100 mh-auto p-0 shadow-xss border-0 rounded-lg overflow-hidden">
        <div class="card-body p-lg-4 bg-white">
            <form @submit.prevent="onSubmit()" class="row justify-content-center">
                <div class="form-group col-lg-6">
                    <label for="" class="text-current fw-600">Full Name<br><small>Nama Lengkap</small></label>
                    <input type="text" v-model="form.fullname" class="form-control" placeholder="Insert your Full Name" required>
                </div>
                <div class="form-group col-lg-6">
                    <label for="" class="text-current fw-600">ID Number<br><small>No. KTP/SIM/Paspor</small></label>
                    <input type="number" v-model="form.id_no" class="form-control" placeholder="Insert your ID Number" required>
                </div>
                <div class="form-group col-lg-12">
                    <label for="" class="text-current fw-600">Information Source<br><small>Sumber Informasi</small></label>
                    <select v-model="form.partner_id" required class="form-control">
                        <option value="" selected disabled>-- Select Information Source --</option>
                        <option :value="item.id" v-for="(item, index) in partner" :key="index">
                            <span>
                            {{ item.title }}
                            </span>
                        </option>
                    </select>
                </div>
                <div class="form-group col-lg-6">
                    <label for="" class="text-current fw-600">Test Program<br><small>Jenis Tes</small></label>
                    <select v-model="form.category_test" class="form-control" @change="getTestDate" required>
                        <option value="" selected disabled>-- Select your Test Program --</option>
                        <option value="TOEFL ITP">TOEFL ITP</option>
                        <option value="TOEFL iBT">TOEFL iBT</option>
                        <option value="TOEIC">TOEIC</option>
                        <option value="IELTS">IELTS</option>
                    </select>
                </div>
                <div class="form-group col-lg-6">
                    <label for="" class="text-current fw-600">Test Date<br><small>Tanggal Tes</small></label>
                    <input v-if="testdate.length == 0" type="text" class="form-control" placeholder="-- Select your Test Date --" disabled>
                    <select v-else v-model="form.test_date" class="form-control" required>
                        <option value="" selected disabled>-- Select your test date --</option>
                        <option :value="item.test_date" v-for="(item,index) in testdate" :key="index">
                        {{formatDate(item.test_date)}}
                        </option>
                    </select>
                    <!-- <input type="date" v-model="form.test_date" class="form-control" placeholder="Insert your Test Date" required> -->
                </div>
                <div class="form-group col-lg-6">
                    <label for="" class="text-current fw-600">Native Country<br><small>Negara Asal</small></label>
                    <input type="text" v-model="form.country" class="form-control" placeholder="Insert your Native Country" required>
                </div>
                <div class="form-group col-lg-6">
                    <label for="" class="text-current fw-600">Native Language<br><small>Bahasa Ibu</small></label>
                    <input type="text" v-model="form.native_language" class="form-control" placeholder="Insert your Native Language" required>
                </div>
                <div class="form-group col-lg-6">
                    <label for="" class="text-current fw-600">Date of Birth<br><small>Tanggal Lahir</small></label>
                    <input type="date" v-model="form.birth_date" class="form-control" placeholder="Insert your Date of Birth" required>
                </div>
                <div class="form-group col-lg-6">
                    <label for="" class="text-current fw-600">Gender<br><small>Jenis Kelamin</small></label>
                    <select v-model="form.sex" class="form-control" required>
                        <option value="" selected disabled>-- Select your Gender --</option>
                        <option value="Female">Female</option>
                        <option value="Male">Male</option>
                    </select>
                </div>
                <div class="form-group col-lg-6">
                    <label for="" class="text-current fw-600">Mobile Number<br><small>Nomor Handphone</small></label>
                    <input type="tel" min="11" v-model="form.no_phone" pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$" required class="form-control" placeholder="Your Phone Number" >
                </div>
                <div class="form-group col-lg-6">
                    <label for="" class="text-current fw-600">Times Taken<br><small>Pernah ikut tes berapa kali ?</small></label>
                    <input type="number" v-model="form.times_taken" min="1" class="form-control" placeholder="Insert your Times Taken" required>
                </div>
                <div class="form-group col-12">
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input mt-2" v-model="check1" required>
                        <label class="form-check-label font-xsss fw-500 text-grey-700" for="exampleCheck1">
                            Make sure your data was correct
                            <br><small>Pastikan seluruh data sudah benar</small>
                        </label>
                    </div>
                </div>
                <div class="form-group col-12">
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input mt-2" v-model="check2" required>
                        <label class="form-check-label font-xsss fw-500 text-grey-700" for="exampleCheck2">
                            Agree to be rescheduled for the next test period if the participant's quota is full
                            <br><small>Setuju untuk dijadwalkan ulang pada periode tes berikutnya apabila kuota peserta penuh</small>
                        </label>
                    </div>
                </div>
                <div class="form-group col-lg-12 text-right mt-2" v-if="!process">
                    <button class="btn btn-current" type="submit" :disabled="!check1 && !check2">Submit</button>
                </div>
                <div class="form-group col-lg-12 text-right mt-2" v-else>
                    <button class="btn btn-current" disabled>Processing...</button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import moment from 'moment'
import axios from 'axios'
export default {
    data() {
        return {
            
            //confirm: false,
            check1: false,
            check2: false,
            process: false,
            testdate: [],
            partner: [],
            form: {
                fullname: '',
                id_no: '',
                partner_id: '',
                category_test: '',
                test_date: '',
                country: '',
                native_language: '',
                birth_date: '',
                sex: '',
                times_taken: '',
                no_phone: '',
            }
        }
    },
    created(){
        this.getPartner()
        this.getTestDate()
    },
    methods: {
        formatDate(date) {
            return moment(date).format('MMMM DD, YYYY');
        },
        async getPartner() {
            await axios.get(`${process.env.VUE_APP_URL_API}/core/toefl/partner`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.partner = res.data
            })
        },
        async getTestDate(){
            this.form.test_date = ''
            if (this.form.category_test != '') {
                await axios.get(`${process.env.VUE_APP_URL_API}/core/toefl/get-test-date?category_test=${this.form.category_test}`,{
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(function (res) {
                        this.testdate = res.data
                }.bind(this));
            }
        },
        async onSubmit() {
            this.process = true
            let data = this.form
            await axios.post(`${process.env.VUE_APP_URL_API}/core/toefl`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.check1 = false
                this.check2 = false
                this.form = {
                    fullname: '',
                    id_no: '',
                    category_test: '',
                    test_date: '',
                    country: '',
                    native_language: '',
                    partner_id: '',
                    birth_date: '',
                    sex: '',
                    times_taken: '',
                    no_phone: '',
                }
                this.$swal({
                    toast: true,
                    title: 'TOEFL!',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            }).catch(err => {
                if (err?.response?.status == 422){
                    this.$swal({
                        toast: true,
                        title: 'TOEFL!',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'TOEFL!',
                        text: 'Failed register!',
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            }).finally(() => this.process = false)
        }
    }
}
</script>